import axios from "axios";
import JSONToQueryParams from "../util/functions/JSONToQueryParams";
import { generateRoutes } from "./base.api";
import findOptions from "./interfaces/findOptions.interface";

const routes = generateRoutes("/applicationProcesses");

const accept = (id: number) => {
    return axios.post(`/applicationProcesses/accept/${id}`);
}

const reject = (id: number) => {
    return axios.post(`/applicationProcesses/reject/${id}`);
}

const viewFinanceSection = (id: number) => axios.get(`/applicationProcesses/viewFinanceSection/${id}`);

export default {
    find: routes.find,
    findOne: routes.findOne,
    findByUser: routes.findByUser,
    update: routes.update,
    archive: routes.remove,
    restore: routes.restore,
    accept,
    reject,
    canApply: async (options: findOptions) => axios.get(`/applicationProcesses/apply?${JSONToQueryParams(options)}`),
    viewFinanceSection,
};
