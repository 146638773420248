import React from "react";
import Head from "./Head";
import Form from "./forms/Form";
import { login } from "../api/users.api";
import { connect } from "react-redux";
import { LoginAction } from "../store/actions/user.actions";
import axios from "axios";
import { Link } from "react-router-dom";
// import { GoogleLogin } from "react-google-login";
// import { useSnackbar } from "notistack";
// import { makeStyles } from "@material-ui/styles";
// import { Button } from "@material-ui/core";
import extractErrorText from "../util/functions/extractErrorText";

// const useStyles = makeStyles((theme) => ({
//   googleButton: {
//     justifyContent: "flex-start",
//   },
// }));
const Login: React.FunctionComponent<{
  LoginAction: Function;
}> = (props) => {
  const [open, setOpen] = React.useState(true);
  // const { enqueueSnackbar } = useSnackbar();
  // const classes = useStyles();

  // const onGoogleLogin = async (response: any) => {
  //   try {
  //     let authenticationResponse = await axios.post(
  //       "/users/auth/google/token",
  //       {
  //         token: response.tokenId,
  //       }
  //     );
  //     enqueueSnackbar(`Welcome ${authenticationResponse.data.user.email}`, {
  //       variant: "success",
  //     });
  //     await props.LoginAction(authenticationResponse.data);
  //   } catch (error) {
  //     enqueueSnackbar(
  //       error?.response?.data ||
  //         error?.message ||
  //         "An unexpected error occured...",
  //       {
  //         variant: "error",
  //       }
  //     );
  //   }
  // };

  return (
    <React.Fragment>
      <Head title="Login" path="/home" />
      <Form
        // withModal
        title="Login"
        description="Please enter your email and password"
        modalOpen={open}
        onModalClose={() => setOpen(false)}
        apiRequest={login}
        successMessage={(body: any) => `Welcome ${body.user.email}!`}
        errorMessage={extractErrorText}
        callback={(body: any) => {
          axios.defaults.headers.Authorization = `${body.token}`;
          props.LoginAction(body);
        }}
        inputs={[
          {
            type: "text",
            required: true,
            name: "email",
          },
          {
            type: "password",
            required: true,
            name: "password",
          },
        ]}
      />
      <Link to="/forgetpassword">Forgot your password?</Link>
      <Link to="/signup">Don't have an account? Sign up</Link>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  LoginAction,
};

export default connect(null, mapDispatchToProps)(Login);
