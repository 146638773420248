import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../styles/Drawer.scss";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
// import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from "@material-ui/icons/Home";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PeopleIcon from "@material-ui/icons/People";
import { Link } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import { LogoutAction } from "../store/actions/user.actions";
import { changeSelectedItem } from "../store/actions/drawer.action";
import AuthGuard from "./guards/AuthGuard";
import MobileGuard from "./guards/MobileGuard";
import DesktopGuard from "./guards/DesktopGuard";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ClassIcon from "@material-ui/icons/Class";
import AppsIcon from "@material-ui/icons/Apps";
import ComponentGuard, { CheckPermissions } from "./guards/ComponentGuard";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import ApartmentIcon from "@material-ui/icons/Apartment";
import ScheduleIcon from "@material-ui/icons/Schedule";
import GradingIcon from "@mui/icons-material/Grading";
import PaymentsIcon from "@mui/icons-material/Payments";
import { ListItemButton } from "@mui/material";
import SegmentIcon from '@mui/icons-material/Segment';
import SchoolIcon from '@mui/icons-material/School';
import QuizIcon from '@mui/icons-material/Quiz';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import { Tooltip } from "@material-ui/core";
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { getMoodleSignInUrl, hasMoodleAccess } from "../api/users.api";
import { useSnackbar } from "notistack";
import extractErrorText, { Err } from "../util/functions/extractErrorText";
import { useQuery } from "react-query";
import InfoIcon from '@mui/icons-material/Info';
import { Business } from "@material-ui/icons";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.down("sm")]: {
      width: 0,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
}));

type DrawerItem = {
  link: string;
  key: string;
  translationIndex: string;
  icon: React.ReactElement;
  permissions?: PermissionsEnum[];
};

type MenuItem = {
  menuItemTranslation: string;
  linkTo?: string;
  menuItemFunction?: (...a: any) => Promise<any> | any;
};

const AppDrawer: React.FunctionComponent<{
  user: any;
  LogoutAction: Function;
  permissions: PermissionsEnum[];
  selectedDrawerIndex: number;
  changeSelectedItem: Function;
}> = (props) => {
  const [open, setOpen] = React.useState(false);
  const [menuAnchor, setMenuAnchor] = React.useState<Element | undefined>(
    undefined
  );
  const [menuOpen, setMenuOpen] = React.useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const AccountMenuItems: MenuItem[] = [
    { linkTo: '/profile', menuItemTranslation: 'PROFILE' },
    {
      menuItemFunction: async () => {
        try {
          let link = document.createElement("a");
          link.href = (await getMoodleSignInUrl()).data.signInURL;
          link.target = "_blank";
          link.click();
        } catch (error) {
          enqueueSnackbar(await extractErrorText(error as Err), { variant: 'error' })
        }
      },
      menuItemTranslation: 'VISIT_MOODLE'
    },
    { linkTo: '/settings', menuItemTranslation: 'SETTINGS' },
    // { menuItemFunction: async () => { }, menuItemTranslation: 'LOGOUT' },
  ];

  const [MenuItems, setMenuItems] = React.useState([] as any[]);
  const [gettable, setGettable] = React.useState<boolean>(false);


  const generator: (status: 'success' | undefined) => React.ReactElement[] = (status: 'success' | undefined) => {
    let menuItems: React.ReactElement[] = [];
    menuItems.push(
      <Link to={AccountMenuItems[0].linkTo || ''} key='1'>
        <MenuItem>{t(AccountMenuItems[0].menuItemTranslation)}</MenuItem>
      </Link>
    );
    if (status) menuItems.push(
      <MenuItem onClick={AccountMenuItems[1].menuItemFunction} key='2'>
        {t(AccountMenuItems[1].menuItemTranslation)}
      </MenuItem>
    );
    menuItems.push(
      <Link to={AccountMenuItems[2].linkTo || ''} key='3'>
        <MenuItem>{t(AccountMenuItems[2].menuItemTranslation)}</MenuItem>
      </Link>
    );
    menuItems.push(
      <MenuItem onClick={() => props.LogoutAction()} key='4'>
        {t("LOGOUT")}
      </MenuItem>
    );
    return menuItems;
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuOpen = (event: React.MouseEvent) => {
    setMenuAnchor(event.currentTarget);
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  useQuery('hasMoodleAccess', () => { return hasMoodleAccess() }, {
    async onSuccess(response) {
      if (response.data.status) {
        setGettable(true);
      }
      else {
        setGettable(false);
      }
    },
    onError(error) {
      setGettable(false);
    },
    refetchInterval: 300000,
  })

  useEffect(() => {
    (async () => {
      let generated = generator(gettable ? 'success' : undefined);
      setMenuItems(generated)
    })()
  }, [gettable])

  const [selectedListItem, setSelectedListItem] = React.useState<number>(props.selectedDrawerIndex);

  const drawerList: DrawerItem[] = [
    { link: '/home', key: 'home', translationIndex: 'HOME_PAGE', icon: <HomeIcon /> },
    { link: '/available-programs', key: 'availablePrograms', translationIndex: 'AVAILABLE_PROGRAMS', icon: <NoteAltIcon /> },
    { link: '/users', key: 'users', translationIndex: 'USERS', icon: <PeopleIcon />, permissions: [PermissionsEnum.ViewEmployees] },
    { link: '/schoolInfo', key: 'schoolInfo', translationIndex: 'SCHOOL_INFO', icon: <InfoIcon />, permissions: [PermissionsEnum.ViewSchoolInfo] },
    { link: '/campuses', key: 'campuses', translationIndex: 'CAMPUSES', icon: <ApartmentIcon />, permissions: [PermissionsEnum.ViewCampuses] },
    { link: '/time-slots', key: 'timeSlots', translationIndex: 'TIME_SLOTS', icon: <ScheduleIcon />, permissions: [PermissionsEnum.ViewTimeSlots] },
    { link: '/videos', key: 'videos', translationIndex: 'VIDEOS', icon: <VideoFileIcon />, permissions: [PermissionsEnum.ViewVideos] },
    { link: '/semesters', key: 'semesters', translationIndex: 'SEMESTERS', icon: <SchoolIcon />, permissions: [PermissionsEnum.ViewSemesters] },
    { link: CheckPermissions(props.permissions, [PermissionsEnum.ViewFAQs]) ? `/faqs` : CheckPermissions(props.permissions, [PermissionsEnum.ViewFAQGroups]) ? "/faqGroups" : '/', key: 'faqs', translationIndex: 'FAQS', icon: <QuizIcon />, permissions: [PermissionsEnum.ViewFAQs, PermissionsEnum.ViewFAQGroups] },
    { link: '/admissionRequirements', key: 'admissionRequirements', translationIndex: 'ADMISSION_REQUIREMENTS', icon: <AssignmentLateIcon />, permissions: [PermissionsEnum.ViewAdmissionRequirements] },
    { link: CheckPermissions(props.permissions, [PermissionsEnum.ViewRoles]) ? `/roles` : CheckPermissions(props.permissions, [PermissionsEnum.ViewCourseOfferingBasedRoles]) ? "/courseOfferingBasedRoles" : '/', key: 'roles', translationIndex: 'ROLES', icon: <VpnKeyIcon />, permissions: [PermissionsEnum.ViewRoles, PermissionsEnum.ViewCourseOfferingBasedRoles] },
    { link: '/application-processes', key: 'applicationProcesses', translationIndex: "APPLICATION_PROCESSES", icon: <AppsIcon />, permissions: [PermissionsEnum.ViewApplicationProcesses] },
    { link: '/students', key: 'students', translationIndex: 'STUDENTS', icon: <AssignmentIcon />, permissions: [PermissionsEnum.ViewStudents] },
    { link: '/programs', key: 'programs', translationIndex: 'PROGRAMS', icon: <ClassIcon />, permissions: [PermissionsEnum.ViewPrograms] },
    // { link: '/programs-current', key: 'programsCurrent', translationIndex: 'CURRENT_PROGRAMS', icon: <AppsIcon /> },
    { link: '/departments', key: 'departments', translationIndex: 'DEPARTMENTS', icon: <ClassIcon />, permissions: [PermissionsEnum.ViewDepartments] },
    { link: '/pending-concentration-choices', key: 'pending-concentration-choices', translationIndex: 'PENDING_CONCENTRATION_CHOICES', icon: <AssignmentIcon />, permissions: [PermissionsEnum.ViewPendingConcentrationChoices] },
    { link: '/pending-program-choices', key: 'pending-program-choices', translationIndex: 'PENDING_PROGRAM_CHOICES', icon: <AssignmentIcon />, permissions: [PermissionsEnum.ViewPendingProgramChoices] },
    { link: '/grading-schemas', key: 'grading-schemas', translationIndex: 'GRADING_SCHEMAS', icon: <GradingIcon />, permissions: [PermissionsEnum.ViewGradingSchemas] },
    { link: '/student-payments', key: 'student-payments', translationIndex: 'STUDENT_PAYMENTS', icon: <PaymentsIcon />, permissions: [PermissionsEnum.ViewStudentPayments] },
    { link: '/form-sections', key: 'form-sections', translationIndex: 'FORM_SECTIONS', icon: <SegmentIcon />, permissions: [PermissionsEnum.ViewFormSections] },
    { link: '/referral-forms', key: 'referral-forms', translationIndex: "REFERRAL_FORMS", icon: <SegmentIcon />, permissions: [PermissionsEnum.ViewReferralForms] }
  ];

  if (props.user?.facultyProfile) {
    drawerList.push({
      link: '/faculty',
      key: 'faculty',
      translationIndex: 'FACULTY',
      icon: <Business />,
    })
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx([classes.appBar, "nav-bar"], {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {process.env.REACT_APP_TITLE}
          </Typography>
          <AuthGuard>
            {props.user && (
              <p className="navbar-username">
                <DesktopGuard>{props.user.firstName} {props.user.lastName}</DesktopGuard>
              </p>
            )}
            <IconButton
              aria-owns="account-menu"
              aria-controls="account-menu"
              aria-haspopup="true"
              className="navbar-buttons"
              onClick={handleMenuOpen}
            >
              <AccountCircleIcon />
            </IconButton>
            <Menu
              id="account-menu"
              keepMounted
              anchorEl={menuAnchor}
              open={menuOpen}
              onClose={handleMenuClose}
              onClick={handleMenuClose}
            >
              <MobileGuard>
                <MenuItem>
                  {props.user && (
                    <p className="navbar-username">{props.user.email}</p>
                  )}
                </MenuItem>
              </MobileGuard>
              {MenuItems}
            </Menu>
          </AuthGuard>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx([classes.drawer], {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx("app-drawer", {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar + " app-drawer"}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List /*onClick={handleDrawerClose}*/>
          {drawerList.map((item, index) =>
            <div key={index}>
              <ComponentGuard requiredPermissions={item.permissions || []}>
                <Link to={item.link} >
                  <ListItemButton key={item.key} selected={selectedListItem === index} onClick={() => { setSelectedListItem(index); props.changeSelectedItem(index); }}>
                    <Tooltip title={t(item.translationIndex) || ""}>
                      <ListItemIcon>
                        {item.icon}
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary={t(item.translationIndex)} />
                  </ListItemButton>
                </Link>
              </ComponentGuard>
            </div>
          )}
          {/*<Link to="/home">
            <ListItem button key={"home"} selected={true}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={t("HOME_PAGE")} />
            </ListItem>
            </Link>*/}
          {/* <Link to="/settings">
            <ListItem button key={"settings"}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary={t("SETTINGS")} />
            </ListItem>
          </Link> */}
          {/*<ComponentGuard requiredPermissions={[PermissionsEnum.ViewEmployees]}>
            <Link to="/users">
              <ListItem button key={"users"}>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary={t("USERS")} />
              </ListItem>
            </Link>
          </ComponentGuard>
          <ComponentGuard requiredPermissions={[PermissionsEnum.ViewCampuses]}>
            <Link to="/campuses">
              <ListItem button key={"campuses"}>
                <ListItemIcon>
                  <ApartmentIcon />
                </ListItemIcon>
                <ListItemText primary={t("CAMPUSES")} />
              </ListItem>
            </Link>
          </ComponentGuard>
          <ComponentGuard requiredPermissions={[PermissionsEnum.ViewTimeSlots]}>
            <Link to="/time-slots">
              <ListItem button key={"timeSlots"}>
                <ListItemIcon>
                  <ScheduleIcon />
                </ListItemIcon>
                <ListItemText primary={t("TIME_SLOTS")} />
              </ListItem>
            </Link>
        </ComponentGuard>*/}
          {/* <ComponentGuard
            requiredPermissions={[PermissionsEnum.ViewRecipeDatabase]}
          >
            <Link to="/recipes">
              <ListItem button key={"diet"}>
                <ListItemIcon>
                  <KitchenIcon />
                </ListItemIcon>
                <ListItemText primary={t("RECIPES_DATABASE")} />
              </ListItem>
            </Link>
          </ComponentGuard> */}
          {/*
          <ComponentGuard requiredPermissions={[PermissionsEnum.ViewRoles, PermissionsEnum.ViewCourseOfferingBasedRoles]}>
            <Link to={CheckPermissions(props.permissions, [PermissionsEnum.ViewRoles]) ? `/roles` : CheckPermissions(props.permissions, [PermissionsEnum.ViewCourseOfferingBasedRoles]) ? "/courseOfferingBasedRoles" : '/departments'}>
              <ListItem button key={"roles"}>
                <ListItemIcon>
                  <VpnKeyIcon />
                </ListItemIcon>
                <ListItemText primary={t("ROLES")} />
              </ListItem>
            </Link>
        </ComponentGuard>*/}
          {/* <ComponentGuard requiredPermissions={[PermissionsEnum.ViewCourseOfferingBasedRoles]}>
            <Link to="/CourseOfferingBasedRoles">
              <ListItem button key={"CourseOfferingBasedRoles"}>
                <ListItemIcon>
                  <VpnKeyIcon />
                </ListItemIcon>
                <ListItemText primary={t("ROLES")} />
              </ListItem>
            </Link>
          </ComponentGuard> */}
          {/* <ComponentGuard requiredPermissions={[PermissionsEnum.ViewInstructors]}>
          <Link to="/instructors">
            <ListItem button key={"instructors"}>
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary={t("INSTRUCTORS")} />
            </ListItem>
          </Link>
        </ComponentGuard> */}
          {/* <ComponentGuard requiredPermissions={[PermissionsEnum.ViewStudents]}>
            <Link to="/students">
              <ListItem button key={"students"}>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary={t("STUDENTS")} />
              </ListItem>
            </Link>
          </ComponentGuard>
          <ComponentGuard requiredPermissions={[PermissionsEnum.ViewPrograms]}>
            <Link to="/programs">
              <ListItem button key={"programs"}>
                <ListItemIcon>
                  <ClassIcon />
                </ListItemIcon>
                <ListItemText primary={t("PROGRAMS")} />
              </ListItem>
            </Link>
          </ComponentGuard>
          <Link to="/application-processes">
            <ListItem button key={"application-processes"}>
              <ListItemIcon>
                <AppsIcon />
              </ListItemIcon>
              <ListItemText primary={t("APPLICATION_PROCESSES")} />
            </ListItem>
          </Link>
          <ComponentGuard requiredPermissions={[PermissionsEnum.ViewDepartments]}>
            <Link to="/departments">
              <ListItem button key={"departments"}>
                <ListItemIcon>
                  <ClassIcon />
                </ListItemIcon>
                <ListItemText primary={t("DEPARTMENTS")} />
              </ListItem>
            </Link>
          </ComponentGuard>
          <ComponentGuard
            requiredPermissions={[
              PermissionsEnum.ViewPendingConcentrationChoices,
            ]}
          >
            <Link to="/pending-concentration-choices">
              <ListItem button key={"pending-concentration-choices"}>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary={t("PENDING_CONCENTRATION_CHOICES")} />
              </ListItem>
            </Link>
          </ComponentGuard>
          <ComponentGuard
            requiredPermissions={[PermissionsEnum.ViewPendingProgramChoices]}
          >
            <Link to="/pending-program-choices">
              <ListItem button key={"pending-program-choices"}>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary={t("PENDING_PROGRAM_CHOICES")} />
              </ListItem>
            </Link>
          </ComponentGuard>
          <ComponentGuard
            requiredPermissions={[PermissionsEnum.ViewGradingSchemas]}
          >
            <Link to="/grading-schemas">
              <ListItem button key={"grading-schemas"}>
                <ListItemIcon>
                  <GradingIcon />
                </ListItemIcon>
                <ListItemText primary={t("GRADING_SCHEMAS")} />
              </ListItem>
            </Link>
          </ComponentGuard>
          <ComponentGuard
            requiredPermissions={[PermissionsEnum.ViewStudentPayments]}
          >
            <Link to="/student-payments">
              <ListItem button key={"student-payments"}>
                <ListItemIcon>
                  <PaymentsIcon />
                </ListItemIcon>
                <ListItemText primary={t("STUDENT_PAYMENTS")} />
              </ListItem>
            </Link>
          </ComponentGuard>*/}
        </List>
      </Drawer>
      <div className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </div>
    </div >
  );
};

const mapStateToProps = (store: any) => {
  return {
    user: store.UserReducer.user,
    permissions: store.UserReducer.permissions,
    selectedDrawerIndex: store.DrawerReducer.index,
  };
};

const mapDispatchToProps = {
  LogoutAction,
  changeSelectedItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppDrawer);
