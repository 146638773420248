import React from 'react';
import { Button as MUIButton, Tooltip } from '@material-ui/core';
import Form, { FormProps } from './forms/Form';
import Guard from './guards/Guard';

interface ButtonProps {
  label: string | React.ReactElement,
  color: 'primary' | 'secondary',
  variant?: 'text' | 'outlined' | 'contained';
  size?: 'small' | 'medium' | 'large';
  withForm?: boolean,
  form?: FormProps,
  handleClick?: Function
  tooltip?: string;
  disabled?: boolean;
  onClose?: () => any;
  style?: React.CSSProperties;
}

const Button: React.FunctionComponent<ButtonProps> = (props) => {

  const [modalOpen, setModalOpen] = React.useState(false);

  const handleClick = () => {
    if (props.withForm)
      setModalOpen(true);
    else if (props.handleClick)
      props.handleClick()
  }

  return (
    <React.Fragment>
      <Tooltip title={props.tooltip || ""}>
        <MUIButton
          color={props.color}
          variant={props.variant || 'contained'}
          onClick={handleClick}
          size={props.size}
          disabled={!!props.disabled}
          style={props.style ?? {}}
        >
          {props.label}
        </MUIButton>
      </Tooltip>
      <Guard condition={props.withForm}>
        <Form
          inputs={[]}
          {...props.form}
          withModal
          modalOpen={modalOpen}
          onModalClose={() => {
            if (props.onClose && typeof props.onClose === 'function') props.onClose();
            return setModalOpen(false);
          }}
        />
      </Guard>
    </React.Fragment>
  )
}

export default Button;
