import React, { useEffect, useState } from "react";
import {
  edit,
  getStudentOutstandingBalance,
  getUserStudentDetails,
} from "../api/users.api";
import applicationProcessApi from "../api/applicationProcesses.api";
import { LoginAction } from "../store/actions/user.actions";
import { connect } from "react-redux";
import { TextField, Button as MaterialButton } from "@material-ui/core";
import type { CSSProperties } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import SingleComponent from "../components/SingleCompnent/SingleComponent";
import Head from "../components/Head";
import Button from "../components/Button";
import Guard from "../components/guards/Guard";
import '../styles/Profile.scss';
import { useQuery } from "react-query";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import RenderDate, { FormatDateToTimeZone } from "../components/CollectionComponent/CustomRender/RenderDate";
import { OpenInNew } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { dropCourse, getCurrentCourses } from "../api/enrollment.api";
import GradingIcon from '@mui/icons-material/Grading';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import { EnrollmentEndTypeEnum } from "../util/enum/enrollmentEndType.enum";
import { DateTimePicker } from "@material-ui/pickers";
import extractErrorText from "../util/functions/extractErrorText";
import useFindRequestQueryParams from "../hooks/useFindRequestQueryParams.hook";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import { getStudentApplications } from "../api/users.api";

const styles: {
  inputGrid: CSSProperties;
  passwordInputGrid: CSSProperties;
  namesButton: CSSProperties;
  passwordsButton: CSSProperties;
} = {
  inputGrid: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    gap: 5,
  },
  passwordInputGrid: {
    display: "grid",
    gridTemplateColumns: "50%",
  },
  namesButton: {
    width: "10%",
    marginTop: 10,
  },
  passwordsButton: {
    width: "25%",
    marginTop: 15,
  },
};

const CurrentCourses = (props: { language: string; }) => {
  const { data: coursesData, isLoading, refetch } = useQuery('currentCourses', async () => {
    return (await getCurrentCourses()).data.data;
  });

  const history = useHistory();
  
  const [
    data,
    pagesCount,
    loading,
    page,
    pageSize,
    search,
    onPageChange,
    onPageSizeChange,
    onSearchChange,
    refresh,
    onHideChange,
  ] = useFindRequestQueryParams(applicationProcessApi.findByUser, "applicationProcess/byUser");

  return (
    <>
      <CollectionComponent
        title="Current courses"
        data={coursesData}
        loading={isLoading}
        noEdit
        noDelete
        columns={[
          {
            title: "Course code",
            render: (rowData: any) => {
              return rowData.grade.courseOffering.course_code
            }
          },
          {
            title: "Course name",
            render: (rowData: any) => {
              return (
                rowData.grade.courseOffering.course.translations.find(
                  (translation: { language: string; name: string }) => {
                    return translation.language === props.language;
                  }
                )?.name || "-"
              );
            },
          },
          {
            title: 'Semester',
            render:(rowData:any)=> `${rowData.grade.courseOffering.semester.semester} ${rowData.grade.courseOffering.semester.year}`
          },
          {
            title: "Credit Hours",
            field: 'grade.courseOffering.course.creditHours',
          }
        ]}
        actions={[
          {
            icon: () => <ContentPasteOffIcon />,
            onClick: async (_event: any, rowData: any) => {
              if (!window.confirm("Are you sure you want to drop this course?")) return;
              await dropCourse(rowData.grade.id);
              await refetch();
            },
            condition: (data: any) => data.isDroppable,
            tooltip: "Drop course",
          },
        ]}
      />

      <CollectionComponent
        title="Application Processes"
        loading={loading}
        data={data}
        columns={[
          {
            title: "Name",
            field: "name",
            editable: "never",
            customFilterAndSearch: () => true,
          },
          {
            title: "Semester",
            field: "semester",
            editable: "never",
            render: (row) => {
              if (row?.programOffering?.semester) {
                return `${row.programOffering.semester.semester} ${row.programOffering.semester.year}`;
              } else return "-";
            },
            customFilterAndSearch: () => true,
          },
          {
            title: "Start Date",
            field: "startDate",
            editComponent: (props) => <DateTimePicker format="MMMM dd, yyyy hh:mm a" value={props.value} onChange={(val) => props.onChange(val)} />,
            render: RenderDate(["startDate"]),
          },
          {
            title: "End Date",
            field: "endDate",
            editComponent: (props) => <DateTimePicker format="MMMM dd, yyyy hh:mm a" value={props.value} onChange={(val) => props.onChange(val)} />,
            render: RenderDate(["endDate"]),
          },
        ]}
        onRowUpdate={async (newData: any, oldData: any) => {
          await applicationProcessApi.update(oldData.id, newData);
          await refresh();
        }}
        actions={[
          {
            icon: 'delete',
            tooltip: "Archive application process",
            onClick: async (_event, rowData: {id: number}) => {
              if (!window.confirm("Are you sure you want to archive this application process")) return;
              await applicationProcessApi.archive(rowData.id);
              await refresh();
            },
            condition: (rowData: any) => !rowData.archived,
            requiredPermission: PermissionsEnum.ArchiveApplicationProcess
          },
          {
            icon: 'undo',
            tooltip: "Restore application process",
            onClick: async (_event, rowData: {id: number}) => {
              if (!window.confirm("Are you sure you want to restore this application process")) return;
              await applicationProcessApi.restore(rowData.id);
              await refresh();
            },
            condition: (rowData: any) => rowData.archived,
            requiredPermission: PermissionsEnum.RestoreApplicationProcess
          },
          {
            icon: () => <OpenInNew />,
            tooltip: "View Application Process",
            onClick: (_event, rowData: { id: number }) => {
              history.push(`/application-process/${rowData.id}`);
            },
          },
        ]}
        noDelete
        mainPermission={PermissionsEnum.EditApplicationProcess}
        viewDisabledInSeparateTab
        onHideChange={onHideChange}
        backendPagination
        pagesCount={pagesCount}
        page={page}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        searchText={search}
        onSearchChange={onSearchChange}
        itemName="application process"
        disableKey="archived"
      />
    </>
  );
}
type UserData = {
  firstName: string,
  middleName: string,
  lastName: string,
  arabicName: string,
  birthdate: Date,
};

const Profile = (props: { language: string; user: any; dispatch: any; }) => {
  let { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [data, setData]: [UserData, any] = useState<UserData>({
    firstName: props.user.user.firstName,
    middleName: props.user.user.middleName,
    lastName: props.user.user.lastName,
    arabicName: props.user.user.arabicName,
    birthdate: props.user.user.birthdate,
  });
  const [oldPass, setOldPass]: any = useState("");
  const [newPass, setNewPass]: any = useState("");
  const [editProfile, setEditProfile] = useState(false);

  const { data: student, isLoading: studentIsLoading } = useQuery('myStudentProfile', async () => {
    return (await getUserStudentDetails()).data;
  });

  const { data: outstandingBalance } = useQuery('outstandingBalance', async () => {
  	return (await getStudentOutstandingBalance()).data.outstandingBalance;
  }, {
  	enabled: !!student
  });

  let [
		applicationsData,
		applicationsPagesCount,
		applicationsLoading,
		applicationsPage,
		applicationsPageSize,
		applicationsSearch,
		applicationsOnPageChange,
		applicationsOnPageSizeChange,
		applicationsOnSearchChange,
		// applicationsRefresh,
		// applicationsOnHideChange,
	] = useFindRequestQueryParams(getStudentApplications(props.user.user.id), 'myApplications');

  async function saveName() {
    try {
      let editResponse = await edit(data);
      // delete editResponse.data.roles; // no need since there is no join on the flags attribute in the login also
      delete editResponse.data.flags;
      await LoginAction({
        ...props.user,
        user: {
          ...props.user.user,
          ...editResponse.data.user,
        },
      })(props.dispatch);
      enqueueSnackbar(`Info changed successfully`, { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(await extractErrorText(error), { variant: 'error' })
    }
  }

  function change(e: any, key: string) {
    const fieldNames: string[] = ['firstName', 'middleName', 'lastName', 'arabicName', 'birthdate'];
    if (fieldNames.includes(key)) {
      let value = key === 'birthdate' ? e : e.target.value;
      setData((oldData: UserData) => ({
        ...oldData,
        [key]: value
      }));
    }
  }

  async function changePass() {
    try {
      await edit({
        oldPassword: oldPass,
        newPassword: newPass,
      });
      window.location.reload();
    } catch (err: any) {
      alert(err?.response?.data);
    }
  }

  const [imgTag, setImgTag] = useState<React.ReactElement>(<></>);
  useEffect(() => {
    if (props?.user?.user?.photo)
      setImgTag(<img className='profile-photo' alt='profile' src={props?.user?.user?.photo}></img>)
  }, [])

  return (
    <React.Fragment>
      <Head title="My Profile" path="/profile" />
      <div className="buttons-section">
        <Guard
          condition={editProfile}
          fallback={
            <Button
              label="Edit Profile"
              color="primary"
              handleClick={() => setEditProfile(true)}
            />
          }
        >
          <Button
            label="Back to profile"
            color="primary"
            handleClick={() => setEditProfile(false)}
          />
        </Guard>
      </div>
      <Guard
        condition={editProfile}
        fallback={
          <>
            <div className="container">
              <SingleComponent
                title="Profile"
                fields={[
                  {
                    name: "First Name",
                    value: props.user.user.firstName,
                  },
                  {
                    name: "Last Name",
                    value: props.user.user.lastName,
                  },
                  {
                    name: 'Birthdate',
                    value: FormatDateToTimeZone(props.user.user.birthdate, { includeTimeOfDay: false }),
                  },
                  ...(student
                    ? [
                      {
                        name: "Scholarship",
                        value:
                          student.currentScholarship !== undefined
                            ? `${+student.currentScholarship.amount}%`
                            : "-",
                      },
                      {
                        name: "Outstanding balance",
                        value: outstandingBalance !== undefined ? outstandingBalance : '-'
                      }
                    ]
                    : []),
                  // {
                  //   name: "Program",
                  //   value: api.findOne(+id)
                  // },
                ]}
              />
              {imgTag}
            </div>
            <br />
            <Guard condition={!!student}>
              <CurrentCourses language={props.language} />
            </Guard>
            <CollectionComponent
              loading={studentIsLoading}
              data={student?.enrollments}
              columns={[
                {
                  title: "Enrollment program",
                  render(enrollment) {
                    let programChoice = enrollment.programChoice;
                    if (!programChoice) return "-";
                    return (
                      programChoice?.programOffering?.program?.translations.find(
                        (t: any) => t.language == props.language
                      )?.name || (
                        <p style={{ color: "red" }}>
                          No translation for program
                        </p>
                      )
                    );
                  },
                },
                {
                  title: "Enrollment concentration",
                  render(enrollment) {
                    let concentrationChoice = enrollment.concentrationChoice;
                    if (!concentrationChoice) return "-";
                    return (
                      concentrationChoice?.concentrationOffering.concentration.translations.find(
                        (t: any) => t.language === props.language
                      )?.name || (
                        <p style={{ color: "red" }}>
                          No translation for concentration
                        </p>
                      )
                    );
                  },
                },
                {
                  title: "Start date",
                  render: RenderDate(["startDate"]),
                  editable: "never",
                },
                {
                  title: "Start semester",
                  render(data) {
                    return `${data.startSemester.semester} ${data.startSemester.year}`;
                  },
                  editable: "never",
                },
                {
                  title: "End date",
                  render(data) {
                    let date = data.end?.endDate;
                    return RenderDate([])(date);
                  },
                  editable: "never",
                },
                {
                  title: "End semester",
                  render(data) {
                    return (
                      (data.end?.endSemester &&
                        `${data.end.endSemester.semester} ${data.end.endSemester.year}`) || "-"
                    );
                  },
                  editable: "never",
                },
                {
                  title: "Status",
                  render(data) {
                    if (data.end) {
                      if (data.end.endType === EnrollmentEndTypeEnum.G) return "Graduate";
                      else if (data.end.endType === EnrollmentEndTypeEnum.D) return "Dropped";
                    }
                    else return "Enrolled";
                  },
                  editable: "never",
                },
              ]}
              actions={[
                {
                  icon: () => <OpenInNew />,
                  tooltip: "Open",
                  onClick: (_e, data) => {
                    history.push(`/enrollments/${data.id}`)
                  }
                },
                {
                  icon: () => <GradingIcon />,
                  tooltip: "View grades",
                  onClick: (_e, data) => {
                    history.push(`/unofficial-transcript/${data.id}`);
                  }
                }
              ]}
              title="Enrollments"
              isEditHidden={() => true}
              noDelete
            />
            <CollectionComponent
              data={student?.expenses}
              title={"Expenses"}
              viewOnly
              columns={[
                {
                  field: "payableAmount",
                  title: "Amount"
                },
                {
                  title: "Description",
                  field: "description"
                },
                {
                  title: "Date",
                  render: RenderDate(["date"])
                },
              ]}
              loading={studentIsLoading}
            />

            <CollectionComponent
              title="Student Applications"
              loading={applicationsLoading}
              data={applicationsData}
              columns={[
                { title: "Semester", render: (application) => application.applicationProcess.programOffering.semester.semester + ' ' + application.applicationProcess.programOffering.semester.year },
                { title: 'Program', render: (application) => application.applicationProcess.programOffering.program.translations?.find((t: any) => t.language === props.language)?.name || '-' },
                { title: "Status", render: (application) => application?.reviewed === true ? application.accepted ? "Approved" : "Rejected" : "Not yet reviewed" },
              ]}
              backendPagination
              page={applicationsPage}
              pagesCount={applicationsPagesCount}
              pageSize={applicationsPageSize}
              backendSearch
              onPageChange={applicationsOnPageChange}
              onPageSizeChange={applicationsOnPageSizeChange}
              searchText={applicationsSearch}
              onSearchChange={applicationsOnSearchChange}
              itemName='course application'
              viewOnly
			      />
          </>
        }
      >
        <h1>Profile</h1>
        <div style={styles.inputGrid}>
          <TextField
            label="First Name"
            value={data.firstName}
            onChange={(e) => change(e, "firstName")}
          />
          <TextField
            label="Middle Name"
            value={data.middleName}
            onChange={(e) => change(e, "middleName")}
          />
          <TextField
            label="Last Name"
            value={data.lastName}
            onChange={(e) => change(e, "lastName")}
          />
          <TextField
            label="Aarabic Name"
            value={data.arabicName}
            onChange={(e) => change(e, "arabicName")}
          />
          <DateTimePicker
            label="Birthdate"
            value={data.birthdate}
            onChange={(e) => change(e, "birthdate")}
          />
          {/* <TextField
            label="Program"
            value={program}
            onChange={(e) => change(e, "program")}
            /> */}
        </div>
        <MaterialButton
          variant="contained"
          color="primary"
          onClick={saveName}
          style={styles.namesButton}
        >
          Save Info
        </MaterialButton>
        <Guard condition={!props.user.user.googleID}>
          <h1
            style={{
              marginTop: 30,
              marginBottom: 0,
            }}
          >
            Change Password
          </h1>

          <form style={styles.passwordInputGrid}>
            <TextField
              label="Old Password"
              value={oldPass}
              type="password"
              onChange={(e) => setOldPass(e.target.value)}
            />
            <TextField
              label="New Password"
              value={newPass}
              type="password"
              onChange={(e) => setNewPass(e.target.value)}
            />
            <MaterialButton
              variant="contained"
              color="primary"
              onClick={changePass}
              style={styles.passwordsButton}
            >
              Change Password
            </MaterialButton>
          </form>
        </Guard>
      </Guard>
    </React.Fragment>
  );
};

const mapStateToProps = (store: any) => {
  return {
    user: store.UserReducer,
    language: store.SettingsReducer.language,
  };
};

export default connect(mapStateToProps)(Profile);
