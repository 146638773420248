import React from "react";
import Head from "./Head";
import Form from "./forms/Form";
import { signup } from "../api/users.api";
import extractErrorText from "../util/functions/extractErrorText";
import Button from "../components/Button"
import { useHistory } from "react-router-dom";

const Signup: React.FunctionComponent = () => {
  const [open, setOpen] = React.useState(true);
  const history = useHistory();

  return (
    <React.Fragment>
      <Head title="Signup" path="/home" />
      <Form
        // withModal
        title="Signup"
        description="Please fill in the following inputs"
        modalOpen={open}
        onModalClose={() => setOpen(false)}
        apiRequest={signup}
        successMessage={(body: any) => body}
        errorMessage={extractErrorText}
        inputs={[
          {
            type: "text",
            required: true,
            name: "email",
          },
        ]}
      />
      <Button label="back" color='primary' handleClick={history.goBack} style={{width:'50px'}}/>
    </React.Fragment>
  );
};

export default Signup;
