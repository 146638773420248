import React, { BaseSyntheticEvent, useState } from "react";
import SingleComponent from "../components/SingleCompnent/SingleComponent";
import { getStudent, enroll, addExpense, editStudent } from "../api/users.api";
import expenseApi from "../api/expenses.api";
import { changeCurrentScholarship } from "../api/users.api";
import { useHistory, useParams } from "react-router-dom";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ComponentGuard from "../components/guards/ComponentGuard";
import Button from "../components/Button";
import programsApi from "../api/programs.api";
import { toOptions } from "./recipesDatabase/utils";
import extractErrorText from "../util/functions/extractErrorText";
import { SemesterEnum } from "../util/enum/semester.enum";
import RenderDate, { FormatDateToTimeZone } from "../components/CollectionComponent/CustomRender/RenderDate";
import DeleteIcon from "@material-ui/icons/Delete";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { useSnackbar } from "notistack";
import { acceptScholarship, rejectScholarship, getStudentGrades, getStudentApplications } from "../api/users.api";
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import PermissionsGuard from "../components/guards/PermissionsGuard";
import { MTableAction } from "material-table";
import { OpenInNew } from "@mui/icons-material";
import api from '../api/enrollment.api';
import coursesApi from "../api/courses.api";
import useFindRequestQueryParams from "../hooks/useFindRequestQueryParams.hook";
import { EnrollmentEndTypeEnum } from "../util/enum/enrollmentEndType.enum";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { Button as MuiButton } from "@mui/material";
import Form, { FormInputProps } from "../components/forms/Form";
import { LanguageEnum } from "../util/enum/language.enum";
import { connect } from "react-redux";

type Scholarship = {
	amount: number;
	date: Date;
	comments?: string;
};

const Student = (props: { language: string; }) => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const { id } = useParams<{ id: string }>();

	const [student, setStudent] = React.useState({
		firstName: "",
		lastName: "",
		email: "",
		birthdate: new Date(),
		studentProfile: { scholarships: [] },
	} as {
		firstName: string;
		lastName: string;
		email: string;
		birthdate: Date;
		studentProfile: {
			currentScholarship?: Scholarship;
			scholarships: Scholarship[];
		};
	});


	const [enrollments, setEnrollments] = useState([] as any[]);
	const [files, setFiles] = useState([] as any);
	const [expenses, setExpenses] = useState([] as any[]);
	let [programOptions, setProgramOptions] = useState([] as any);
	let [courseOptions, setCourseOptions] = useState([] as any);
	const { enqueueSnackbar } = useSnackbar();

	let [
		gradesData,
		gradesPagesCount,
		gradesLoading,
		gradesPage,
		gradesPageSize,
		gradesSearch,
		gradesOnPageChange,
		gradesOnPageSizeChange,
		gradesOnSearchChange,
		gradesRefresh,
		gradesOnHideChange,
	] = useFindRequestQueryParams(getStudentGrades(+id), 'grades');

	let [
		applicationsData,
		applicationsPagesCount,
		applicationsLoading,
		applicationsPage,
		applicationsPageSize,
		applicationsSearch,
		applicationsOnPageChange,
		applicationsOnPageSizeChange,
		applicationsOnSearchChange,
		// applicationsRefresh,
		// applicationsOnHideChange,
	] = useFindRequestQueryParams(getStudentApplications(+id), 'applications');

	let extraInputs: FormInputProps[] = [
		{
			name: "End semester",
			type: "select",
			keyName: "endSemester",
			options: Object.keys(SemesterEnum).map((e) => ({ label: e, value: e })),
			required: true
		},
		{
			name: "End year",
			type: "number",
			keyName: "endYear",
			required: true
		},
	];

	const extraGradeInput: FormInputProps = {
		type: "decimal",
		name: "Percentage grade",
		keyName: "percentageGrade",
		required: true,
	};
	const extraCourseGradeExpenseInputs: [FormInputProps, FormInputProps] = [
		{
			type: "decimal",
			name: "Expense value",
			defaultValue: 0,
			keyName: "expenseValue",
			required: true,
		},
		{
			type: "text",
			name: "Expense description",
			keyName: "expenseDescription",
			required: false,
		}];

	const [enrollmentStatusModalForId, setEnrollmentStatusModalForId] = useState<number | null>(null);
	const [enrollmentStatusInputs, setEnrollmentStatusInputs] = useState(extraInputs);

	const [courseGradeInputs, setCourseGradeInputs] = useState<{
		courseGradeExpenseInputs: [FormInputProps, FormInputProps] | [],
		askForGradeInput: [FormInputProps] | [],
		dropDateInput: [FormInputProps] | [],
		defaultValues: {
			mapToMoodle: boolean,
			// dropDate: Date | null,
		},
	}>({
		courseGradeExpenseInputs: [],
		askForGradeInput: [extraGradeInput],
		dropDateInput: [],
		defaultValues: {
			mapToMoodle: false,
			// dropDate: null,
		},
	});

	const extraDropDateInput: FormInputProps = {
		type: 'dateTime',
		name: 'Drop date',
		keyName: 'dropDate',
		required: true,
		// onChange: (value) => setCourseGradeInputs((current: any) => ({
		//   ...current,
		//   defaultValues: {
		//     mapToMoodle: current.mapToMoodle,
		//     dropDate: value,
		//   },
		// })),
	};

	function onEnrollmentEndTypeChange(event: BaseSyntheticEvent) {
		let value = event.target.value;
		if (value === "enrolled") {
			if (enrollmentStatusInputs.length > 0) setEnrollmentStatusInputs([]);
		} else if (value === EnrollmentEndTypeEnum.G || value === EnrollmentEndTypeEnum.D) {
			if (enrollmentStatusInputs.length == 0) {
				setEnrollmentStatusInputs(extraInputs);
			}
		}
	}


	const loadStudent = async () => {
		try {
			setLoading(true);
			const { data } = await getStudent(+id);
			setStudent(data);
			setEnrollments(data.studentProfile.enrollments);
			setExpenses(data.studentProfile.expenses);
			setFiles(data.files);
			try {
				let {
					data: { data },
				} = await programsApi.getAll();
				setProgramOptions(toOptions(data, ["translations"]));
			} catch {
				// could not get all programs
			}
			try {
				let { data: { data } } = await coursesApi.find();
				setCourseOptions(((courses: any) => {
					let options: { value: string, label: string }[] = [];
					courses.forEach((course: any) => {
						options.push({ value: course.id, label: course.department.code + course.code })
					});
					return options;
				})(data));
			} catch {
				// could not get all courses
			}
			setLoading(false);
		} catch {
			// TODO: remove comment
			if (process.env.NODE_ENV === 'development') return
			history.push("/");
		}
	};
	const refresh = loadStudent;

	React.useEffect(() => {
		loadStudent();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<React.Fragment>
			<SingleComponent
				title=""
				fields={[
					{
						name: "Name",
						value: student?.firstName + " " + student?.lastName,
					},
					{
						name: "Email",
						value: student?.email,
					},
					{
						name: "Birthdate",
						value: FormatDateToTimeZone(student?.birthdate, { includeTimeOfDay: false }),
						edit: {
							form: {
								title: "Edit Student Birthdate",
								inputs: [
									{
										type: "dateTime",
										name: "Birthdate",
										keyName: "birthdate",
										required: true,
										defaultValue: student?.birthdate?.toString(),
									},
								],
								apiRequest: async (dataa: any) => {
									let user = (student as any).user || { id: +id };
									user.birthdate = dataa.birthdate;
									return await editStudent({
										id: +id,
										user,
									});
								},
								callback: () => refresh(),
								successMessage: () => 'Student birthdate edited successfully!',
								errorMessage: extractErrorText,
							},
							editPermissions: [PermissionsEnum.EditStudents],
						},
					},
					{
						name: "Scholarship amount",
						value: student?.studentProfile?.currentScholarship?.amount,
					},
					{
						name: "Scholarship comments",
						value: student?.studentProfile?.currentScholarship?.comments,
					},
				]}
			/>
			<div className="buttons-section">
				<ComponentGuard
					requiredPermissions={[PermissionsEnum.CreateStudentEnrollments]}
				>
					<Button
						color="primary"
						label="Create Student Enrollment"
						withForm
						form={{
							title: "Create Student Enrollment",
							description:
								"Enter the details of the enrollment you would like to create.",
							apiRequest: async (data: any) => {
								return await enroll(+id, data);
							},
							inputs: [
								{
									type: "select",
									name: "Program",
									keyName: "programId",
									required: true,
									options: programOptions,
								},
								{
									type: "select",
									name: "Start Semester",
									keyName: "startSemester",
									required: true,
									options: Object.values(SemesterEnum).map((semester) => {
										return {
											label: semester,
											value: semester,
										};
									}),
								},
								{
									type: "number",
									name: "Start Year",
									keyName: "startYear",
									required: true,
								},
								{
									type: "select",
									name: "End Semester",
									keyName: "endSemester",
									required: false,
									options: Object.values(SemesterEnum).map((semester) => {
										return {
											label: semester,
											value: semester,
										};
									}),
								},
								{
									type: "text",
									name: "End Year",
									keyName: "endYear",
									required: false,
								},
							],
							successMessage: () => {
								refresh();
								return "Enrollment created successfully!";
							},
							errorMessage: extractErrorText,
						}}
					/>
				</ComponentGuard>
				<ComponentGuard
					requiredPermissions={[PermissionsEnum.AddStudentExpense]}
				>
					<Button
						color="primary"
						label="Add expense to student"
						withForm
						form={{
							title: "Add expense to student",
							description:
								"Enter the details of the expense you would like to add to this student.",
							apiRequest: async (data: any) => {
								return await addExpense(+id, data);
							},
							inputs: [
								{
									type: "decimal",
									name: "Amount",
									keyName: "originalAmount",
									required: true,
								},
								{
									type: "text",
									name: "Comments/description",
									keyName: "description",
									required: false,
								},
								{
									type: "checkbox",
									name: "Scholarship does not apply",
									keyName: "scholarshipApplicable",
									required: false,
								},
							],
							successMessage: () => {
								refresh();
								return "Student expense added successfully!";
							},
							errorMessage: extractErrorText,
						}}
					/>
				</ComponentGuard>
				<ComponentGuard
					requiredPermissions={[PermissionsEnum.ModifyStudentScholarship]}
				>
					<Button
						color="primary"
						label="Change student scholarship"
						withForm
						form={{
							title: "Change student's scholarship",
							description: "Change this student's current scholarship",
							inputs: [
								{
									name: "Percentage",
									required: true,
									type: "decimal",
									keyName: "amount",
								},
								{
									name: "Comments",
									required: true,
									type: "text",
									keyName: "comments",
								},
							],
							successMessage: () => {
								refresh();
								return "Student scholarship changed successfully!";
							},
							errorMessage: extractErrorText,
							apiRequest: (data: any) => changeCurrentScholarship(+id, data),
						}}
					/>
				</ComponentGuard>
				<ComponentGuard requiredPermissions={[PermissionsEnum.AddStudentCourseHistory]}>
					<Button
						color="primary"
						label="Add course taken by student"
						withForm
						onClose={() => setCourseGradeInputs({
							askForGradeInput: [extraGradeInput],
							courseGradeExpenseInputs: [],
							dropDateInput: [],
							defaultValues: {
								mapToMoodle: false,
								// dropDate: null,
							},
						})}
						form={{
							title: "Add course taken by student",
							description:
								"Add details for the course previously taken by the student",
							apiRequest: async (data: any) => { return await api.addCourseHistoryToStudentProfile(+id, data) },
							inputs: [
								{
									type: "selectAutocomplete",
									name: "Course",
									keyName: "courseId",
									required: true,
									options: courseOptions,
								},
								{
									type: "select",
									name: "Semester",
									keyName: "semester",
									required: true,
									options: Object.values(SemesterEnum).map((semester) => {
										return {
											label: semester,
											value: semester,
										};
									}),
								},
								{
									type: "number",
									name: "Year",
									keyName: "year",
									required: true,
								},
								{
									type: "select",
									name: "Grade type",
									keyName: "type",
									required: true,
									defaultValue: 'Graded',
									options: [{ value: 'Credit', label: 'Credit' }, { value: 'Graded', label: 'Graded' }, { value: 'Pass', label: 'Pass' }, { value: 'Fail', label: 'Fail' }, { value: 'Enroll', label: 'Enroll' }, { value: 'Drop', label: 'Drop' }],
									onChange: (event) => {
										if (event?.target?.value === 'Graded') {
											if (courseGradeInputs.askForGradeInput.length === 0)
												setCourseGradeInputs(current => ({
													...current,
													askForGradeInput: [extraGradeInput],
												}));
											if (courseGradeInputs.dropDateInput.length === 1)
												setCourseGradeInputs(current => ({
													...current,
													dropDateInput: [],
												}));
										}
										else if (event?.target?.value === 'Drop') {
											if (courseGradeInputs.dropDateInput.length === 0) {
												let currentTime = new Date(Date.now());
												setCourseGradeInputs(current => ({
													...current,
													// defaultValues: {
													//   mapToMoodle: current.defaultValues.mapToMoodle,
													//   dropDate: currentTime,
													// },
													dropDateInput: [{
														...extraDropDateInput,
														// defaultValue: currentTime.getTime(),
													}],
												}));
											}
											if (courseGradeInputs.askForGradeInput.length === 1)
												setCourseGradeInputs(current => ({
													...current,
													askForGradeInput: [],
												}));
										}
										else {
											if (courseGradeInputs.askForGradeInput.length === 1)
												setCourseGradeInputs(current => ({
													...current,
													askForGradeInput: [],
												}));
											if (courseGradeInputs.dropDateInput.length === 1)
												setCourseGradeInputs(current => ({
													...current,
													dropDateInput: [],
												}));
										}
									},
								},
								...courseGradeInputs.askForGradeInput,
								...((courseGradeInputs.dropDateInput[0]) ?
									[{
										...(courseGradeInputs.dropDateInput[0]),
										defaultValue: /*courseGradeInputs.defaultValues.dropDate? */new Date(Date.now()).getTime(),
									}]
									: []),// for component re-renders when its position in input array changes
								{
									type: 'selectAutocomplete',
									required: true,
									keyName: 'expenseType',
									name: 'Expense',
									defaultValue: courseGradeInputs.courseGradeExpenseInputs.length === 0 ? 'null' : 'set', // for component re-renders when its position in input array changes
									options: [{ value: 'null', label: 'Default' }, { value: 'set', label: 'Manage expense' }],
									onChange: (value) => {
										if (value?.value === 'set') {
											if (courseGradeInputs.courseGradeExpenseInputs.length === 0) {
												setCourseGradeInputs(current => ({
													...current,
													courseGradeExpenseInputs: [...extraCourseGradeExpenseInputs],
												}));
											}
										}
										else if (value?.value === 'null') if (courseGradeInputs.courseGradeExpenseInputs.length === 2) {
											setCourseGradeInputs(current => ({
												...current,
												courseGradeExpenseInputs: [],
											}));
										}
									},
								},
								...courseGradeInputs.courseGradeExpenseInputs,
								{
									type: "checkbox",
									name: "Map to moodle",
									keyName: "moodle",
									required: false,
									defaultValue: courseGradeInputs.defaultValues.mapToMoodle,
									onChange: (value) => setCourseGradeInputs(current => ({
										...current,
										defaultValues: {
											mapToMoodle: value,
											// dropDate: current.defaultValues.dropDate
										},
									}))
								},
							],
							successMessage: () => {
								gradesRefresh();
								return "Course added successfully!";
							},
							errorMessage: extractErrorText,
						}}
					/>
				</ComponentGuard>
				<ComponentGuard requiredPermissions={[PermissionsEnum.ViewStudentFinancialData]}>
					<MuiButton variant="contained" color="primary" onClick={() => history.push(`/student/${id}/financial-data`)}>
						Show financial data
					</MuiButton>
				</ComponentGuard>
			</div>
			<CollectionComponent
				title="Enrollments"
				loading={loading}
				data={enrollments}
				columns={[
					{
						title: "Start semester",
						render: (enrollment) =>
							`${enrollment.startSemester.semester} ${enrollment.startSemester.year}`,
					},
					{
						title: "End semester",
						render: (enrollment) =>
							enrollment.end?.endSemester
								? `${enrollment.end.endSemester.semester} ${enrollment.end.endSemester.year}`
								: `-`,
					},
					{
						title: "End date",
						render(data) {
							let date = data.end?.endDate;
							return RenderDate([])(date);
						},
						editable: "never",
					},
					{
						title: "Status",
						render(data) {
							if (data.end) {
								if (data.end.endType === EnrollmentEndTypeEnum.G) return "Graduate";
								else if (data.end.endType === EnrollmentEndTypeEnum.D) return "Dropped";
							}
							else return "Enrolled";
						},
						editable: "never",
					},
					{
						title: "Current program choice",
						render: (enrollment) => enrollment?.currentProgramChoice ?
							enrollment.currentProgramChoice.programOffering.program.translations.find(
								(translation: any) => {
									return translation.language === props.language;
								}
							)?.name ||
								(
									<span style={{ color: "red" }}>No translation found</span>
								)
									: "-",
					},
				]}
				viewOnly
				actions={[
					{
						icon: OpenInNewIcon,
						requiredPermission: PermissionsEnum.ViewEnrollmentDetails,
						onClick: (_e: any, row: any) => {
							history.push(`/enrollments/${row.id}`);
						},
					},
					{
						icon: AutorenewIcon,
						onClick(_e: any, row: any) {
							setEnrollmentStatusModalForId(row.id);
						},
						requiredPermission: PermissionsEnum.EditEnrollmentStatus,
						tooltip: "Set enrollment status"
					}
				]}
			/>
			<ComponentGuard
				requiredPermissions={[PermissionsEnum.ViewStudentExpenses]}
			>
				<CollectionComponent
					title="Expenses"
					loading={loading}
					data={expenses}
					noDelete
					onRowUpdate={async (_e: any, data: any) => {
						let result = await expenseApi.update(data.id, { comments: _e?.description });
						refresh();
						enqueueSnackbar(result?.data, { variant: "success" });
						return result;
					}}
					mainPermission={PermissionsEnum.EditStudentExpense}
					disableKey="archived"
					columns={[
						{
							title: "Amount",
							render: (expense) => {
								return expense.scholarshipApplicable
									? expense.payableAmount
									: expense.originalAmount;
							},
						},
						{
							title: "Comments",
							field: "description",
						},
						{
							title: "Scholarship applied",
							render: (expense) =>
								expense.scholarshipApplicable ? "Yes" : "No",
						},
						{
							title: "Date",
							render: RenderDate(["date"]),
						},
					]}
					actions={[
						{
							icon: () => <DeleteIcon />,
							tooltip: "Archive expense",
							requiredPermission: PermissionsEnum.ArchiveStudentExpense,
							onClick: async (_e: any, data: any) => {
								const confirmed = window.confirm(
									"Are you sure you want to delete this expense?"
								);
								if (!confirmed) return;
								await expenseApi.archive(data.id);
								refresh();
								return { data: "Expense archived successfuly!" };
							},
							condition: (row: any) => {
								return !row.archived;
							},
						},
						{
							icon: "undo",
							tooltip: "Restore expense",
							requiredPermission: PermissionsEnum.RestoreStudentExpense,
							onClick: async (_e: any, data: any) => {
								const confirmed = window.confirm(
									"Are you sure you want to restore this expense?"
								);
								if (!confirmed) return;
								await expenseApi.restore(data.id);
								await refresh();
								return { data: "Expense restored successfuly!" };
							},
							condition: (row: any) => {
								return row.archived;
							},
						},
						{
							icon: () => <AttachMoneyIcon />,
							condition: (row: any) => !row.scholarshipApplicable,
							tooltip: "Apply scholarship to expense",
							requiredPermission: PermissionsEnum.ApplyScholarshipToStudentExpense,
							onClick: async (_e: any, data: any) => {
								let result = await expenseApi.applyScholarshipToExpense(data.id);
								refresh();
								return result;
							},
						},
						{
							icon: () => <MoneyOffIcon />,
							condition: (row: any) => row.scholarshipApplicable,
							tooltip: "Disregard student scholarship for this expense",
							requiredPermission: PermissionsEnum.RemoveScholarshipFromStudentExpense,
							onClick: async (_e: any, data: any) => {
								let result = await expenseApi.removeScholarshipForExpense(data.id);
								refresh();
								return result;
							},
						},
					]}
				/>
			</ComponentGuard>
			<ComponentGuard
				requiredPermissions={[PermissionsEnum.ViewStudentScholarshipHistory]}
			>
				<CollectionComponent
					data={student.studentProfile.scholarships}
					title="Scholarship history"
					loading={loading}
					columns={[
						{
							title: "Amount",
							field: "amount",
						},
						{
							title: "Comments",
							field: "comments",
						}, {
							title: "Date",
							field: "date",
							render: RenderDate(["date"]),
						},
						{
							title: "Approved",
							field: "approved",
							render: (row) => row.approved ? "Yes" : "No",
						},
					]}
					actions={[
						{
							icon: () => <AttachMoneyIcon />,
							condition: (row: any) => !row.approved,
							tooltip: "Accept scholarship",
							requiredPermission: PermissionsEnum.ModifyStudentScholarship,
							onClick: async (_e: any, data: any) => {
								let result = await acceptScholarship(data.id);
								refresh();
								return result;
							},
						},
						{
							icon: () => <MoneyOffIcon />,
							condition: (row: any) => row.approved,
							tooltip: "Reject student scholarship",
							requiredPermission: PermissionsEnum.ModifyStudentScholarship,
							onClick: async (_e: any, data: any) => {
								let result = await rejectScholarship(data.id);
								refresh();
								return result;
							},
						},
						{
							icon: () => <LocalHospitalIcon />,
							tooltip: "View financial aid request",
							render: (p: any) => {
								return (
									<PermissionsGuard
										requiredPermissions={[PermissionsEnum.ViewStudentApplicationArabicFinanceSection, PermissionsEnum.ViewStudentApplicationEnglishFinanceSection]}
									>
										<MTableAction {...p} />
									</PermissionsGuard>
								);
							},
							onClick: async (_e: any, data: any) => {
								history.push(`/student/${id}/financialAid`);
							},
						},
					]}
					viewOnly
				/>
			</ComponentGuard>
			<ComponentGuard
				requiredPermissions={[PermissionsEnum.ViewStudentFiles]}
			>
				<CollectionComponent
					title="Files"
					data={files}
					viewOnly
					loading={loading}
					columns={[
						{
							title: "File name",
							field: "displayName"
						},
					]}
					actions={[
						{
							icon: () => <OpenInNew />,
							tooltip: "Open file",
							onClick: (_e, row) => {
								window.open(row.link, "_blank");
							}
						}
					]}
				/>
			</ComponentGuard>
			<CollectionComponent
				title="Student Grades"
				loading={gradesLoading}
				data={gradesData}
				columns={[
					{ title: "Code", render: (grade) => grade.departmentCode + grade.courseCode },
					{ title: 'Name', render: (grade) => grade.translations?.find((t: any) => t.language === LanguageEnum.EN)?.name || '-' },
					{ title: "Semester", render: (grade) => grade.semester.semester + " " + grade.semester.year, },
					{ title: "Grade", render: (grade) => { return grade.grade } },
					{ title: 'Drop date', render: (grade) => RenderDate(['dropDate'])(grade) },
				]}
				backendPagination
				page={gradesPage}
				pagesCount={gradesPagesCount}
				pageSize={gradesPageSize}
				backendSearch
				onPageChange={gradesOnPageChange}
				onPageSizeChange={gradesOnPageSizeChange}
				searchText={gradesSearch}
				onSearchChange={gradesOnSearchChange}
				itemName='course grade'
				viewOnly
			/>
			<CollectionComponent
				title="Student Applications"
				loading={applicationsLoading}
				data={applicationsData}
				columns={[
					{ title: "Semester", render: (application) => application.applicationProcess.programOffering.semester.semester + ' ' + application.applicationProcess.programOffering.semester.year },
					{ title: 'Program', render: (application) => application.applicationProcess.programOffering.program.translations?.find((t: any) => t.language === LanguageEnum.EN)?.name || '-' },
					{ title: "Status", render: (application) => application?.reviewed === true ? application.accepted ? "Approved" : "Rejected" : "Not yet reviewed" },
				]}
				backendPagination
				page={applicationsPage}
				pagesCount={applicationsPagesCount}
				pageSize={applicationsPageSize}
				backendSearch
				onPageChange={applicationsOnPageChange}
				onPageSizeChange={applicationsOnPageSizeChange}
				searchText={applicationsSearch}
				onSearchChange={applicationsOnSearchChange}
				itemName='course application'
				viewOnly
			/>
			<PermissionsGuard requiredPermissions={[PermissionsEnum.EditEnrollmentStatus]}>
				<Form
					inputs={
						[
							{
								name: "End type",
								keyName: "endType",
								type: "select",
								onChange: onEnrollmentEndTypeChange,
								options: [
									{
										label: "Graduation",
										value: EnrollmentEndTypeEnum.G,
									},
									{
										label: "Drop",
										value: EnrollmentEndTypeEnum.D,
									},
									{
										label: "Enrolled",
										value: "enrolled",
									},
								],
								required: true,
							}, ...enrollmentStatusInputs
						]}
					apiRequest={async (data: any) => {
						if (enrollmentStatusModalForId === null) return;
						await api.update(+enrollmentStatusModalForId, data);
						await refresh();
					}}
					successMessage={() => "Enrollment status set"}
					errorMessage={extractErrorText}
					withModal
					modalOpen={enrollmentStatusModalForId !== null}
					onModalClose={() => {
						setEnrollmentStatusModalForId(null);
						if (enrollmentStatusInputs.length === 0) setEnrollmentStatusInputs(extraInputs);
					}}
				/>
			</PermissionsGuard>
		</React.Fragment>
	);
};

const mapStateToProps = (store: any) => {
  return {
    userId: store.UserReducer.user.id,
    language: store.SettingsReducer.language
  }
}

export default connect(mapStateToProps)(Student);
